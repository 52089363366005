.react-calendar {
  //width: 600px;
  width:100%;
  max-width: 100%;
  //margin:2rem;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  font-family: Poppins;
  line-height: 2.5rem;
  border:none;
}
 
.react-calendar__navigation button {
  color: #e91e63;
  font-weight:bold;
  min-width: 44px;
  background: none;
  font-size: 24px;
  margin-y: 8px;
}
 
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
}
 
/* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
 } */
 
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #e91e63;
  border-radius: 6px;
}
 
.react-calendar__tile--now {
  background: #e91e6333;
  border-radius: 6px;
  font-weight: bold;
  color: #e91e63;
}
 
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #e91e6333;
  border-radius: 6px;
  font-weight: bold;
  color: #e91e63;
}
 
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
 
.react-calendar__tile--active {
  background: #e91e63;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}
 
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #e91e63;
  color: white;
}
 
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
 
.react-calendar__tile--range {
  background: #f8f8fa;
  color: #e91e63;
  border-radius: 0;
}
 
.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #e91e63;
  color: white;
}
 
.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #e91e63;
  color: white;
}